import React from 'react';
import { graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { Link as SmoothScroll } from 'react-scroll'

import Layout from '../components/layout';
import PageHero from '../components/pageHero/PageHero';
import Section from '../components/section/Section';
import House from "../components/house/House";
import Slider from "react-slick";
import SimpleReactLightbox from "simple-react-lightbox";
import Gallery from "../components/gallery/Gallery";

const postTemplate = ({data: {placeData, houses: {edges: houses} } }) => {
    const placeContent = placeData.content.trim(/\r?\n|\r/).split(`<!--more-->`)

    const settings = {
        centerMode: true,
        centerPadding: 0,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 500,
        focusOnSelect: true,
        waitForAnimate: false,
        cssEase: 'ease-out',
        arrows: true
    };

    const footIcon = <svg xmlns="http://www.w3.org/2000/svg" width="120" height="105">
        <path id="Path_676" data-name="Path 676" d="M-16.517-68.38a2.65,2.65,0,0,0,1.23,1.666,2.625,2.625,0,0,0,2.182.317l3.491-.793A2.988,2.988,0,0,0-7.869-68.5a2.477,2.477,0,0,0,.317-2.1L-8.98-76a5.916,5.916,0,0,0-2.936-3.848,6.62,6.62,0,0,0-4.84-.754,3.219,3.219,0,0,0-1.706,1.27,2.445,2.445,0,0,0-.357,2.142Zm3.412-1.9-1.666-6.665a3.178,3.178,0,0,1,1.309.635,2.5,2.5,0,0,1,.833,1.27l1.111,4.364Zm-23.566-5.4a1.236,1.236,0,0,0-.912.357,1.236,1.236,0,0,0-.357.912v25.391a1.236,1.236,0,0,0,.357.912,1.236,1.236,0,0,0,.912.357h1.27a1.236,1.236,0,0,0,.912-.357,1.236,1.236,0,0,0,.357-.912V-68.7h3.809a1.811,1.811,0,0,0,1.428-.635l2.539-2.936,1.984,7.617L-28.419-60.6a4.211,4.211,0,0,0-1.27,3.094v7.855a1.836,1.836,0,0,0,.555,1.349,1.836,1.836,0,0,0,1.349.555,1.836,1.836,0,0,0,1.349-.555,1.836,1.836,0,0,0,.555-1.349v-7.855a.538.538,0,0,1,.159-.4l5.872-5.872A4.223,4.223,0,0,0-18.7-65.761a4.852,4.852,0,0,0,.04-2.222l-1.825-7.22a3.778,3.778,0,0,0-1.389-2.182,3.954,3.954,0,0,0-2.5-.833,3.9,3.9,0,0,0-2.856,1.19L-31.2-72.506h-2.936v-1.9a1.236,1.236,0,0,0-.357-.912,1.236,1.236,0,0,0-.912-.357Zm19.519,12.537a7.4,7.4,0,0,1-.873,1.111l-2.222,2.222,2.7,10.632a1.823,1.823,0,0,0,.674,1.031,1.841,1.841,0,0,0,1.151.4,1.5,1.5,0,0,0,.476-.079,1.809,1.809,0,0,0,1.151-.833,1.689,1.689,0,0,0,.2-1.468Zm-9.363-17.615a3.673,3.673,0,0,0,2.7-1.111,3.673,3.673,0,0,0,1.111-2.7,3.673,3.673,0,0,0-1.111-2.7,3.673,3.673,0,0,0-2.7-1.111,3.673,3.673,0,0,0-2.7,1.111,3.673,3.673,0,0,0-1.111,2.7,3.673,3.673,0,0,0,1.111,2.7A3.673,3.673,0,0,0-26.515-80.758Z" transform="translate(130 222) scale(2.5)" fill="#FFF"/>
    </svg>;
    const carIcon = <svg xmlns="http://www.w3.org/2000/svg" width="120" height="105">
        <path id="Path_666" data-name="Path 666" d="M-7.261-64.674a.986.986,0,0,1,.822.389,1.117,1.117,0,0,1,.216.909L-6.742-61.3a.961.961,0,0,1-.389.562,1.1,1.1,0,0,1-.649.216h-.952a8.325,8.325,0,0,1,1.125,4.153V-44.6a2,2,0,0,1-.606,1.471,2,2,0,0,1-1.471.606h-1.384a2,2,0,0,1-1.471-.606,2,2,0,0,1-.606-1.471v-2.077H-43.6V-44.6a2,2,0,0,1-.606,1.471,2,2,0,0,1-1.471.606h-1.384a2,2,0,0,1-1.471-.606A2,2,0,0,1-49.14-44.6V-56.368a8.325,8.325,0,0,1,1.125-4.153h-.952a1.1,1.1,0,0,1-.649-.216.961.961,0,0,1-.389-.562l-.519-2.077a1.117,1.117,0,0,1,.216-.909.986.986,0,0,1,.822-.389h4.5l2.769-6.749a6.631,6.631,0,0,1,2.509-3.158,6.8,6.8,0,0,1,3.894-1.168h14.883a6.8,6.8,0,0,1,3.894,1.168,6.631,6.631,0,0,1,2.509,3.158l2.769,6.749Zm-31.15-5.192-2.077,5.192H-16.26l-2.077-5.192a2.942,2.942,0,0,0-1.038-1.255,2.685,2.685,0,0,0-1.557-.476H-35.815a2.685,2.685,0,0,0-1.557.476A2.942,2.942,0,0,0-38.411-69.866Zm26.65,17.652v-4.153a4.005,4.005,0,0,0-1.211-2.942,4.005,4.005,0,0,0-2.942-1.211h-24.92a4.005,4.005,0,0,0-2.942,1.211,4.005,4.005,0,0,0-1.211,2.942v4.153a1.348,1.348,0,0,0,.389,1,1.348,1.348,0,0,0,1,.389h30.458a1.348,1.348,0,0,0,1-.389A1.348,1.348,0,0,0-11.76-52.214Zm-27.689-6.23a4.115,4.115,0,0,1,2.682,1.428A3.983,3.983,0,0,1-35.3-54.291q0,.952-1.125,1.211a11.465,11.465,0,0,1-2.423.173h-.606a2.7,2.7,0,0,1-1.99-.779,2.7,2.7,0,0,1-.779-1.99,2.7,2.7,0,0,1,.779-1.99A2.7,2.7,0,0,1-39.449-58.444Zm22.151,0a2.7,2.7,0,0,1,1.99.779,2.7,2.7,0,0,1,.779,1.99,2.7,2.7,0,0,1-.779,1.99,2.7,2.7,0,0,1-1.99.779H-17.9a11.465,11.465,0,0,1-2.423-.173q-1.125-.26-1.125-1.211a3.983,3.983,0,0,1,1.471-2.726A4.115,4.115,0,0,1-17.3-58.444Z"  transform="translate(130 200) scale(2.5)" fill="#FFF"/>
    </svg>;

    return(
        <Layout>
            <PageHero 
            header={placeData.title} className="pb-0 pb-lg-1" background={placeData.featured_media !== null ? placeData.featured_media.localFile.publicURL : ''}>
                <div className="row align-items-end">
                    <div className="col-12 col-lg-8 mb-lg-10 d-flex d-lg-block">
                        {placeData.acf.subtitle ? <div className="hero-excerpt pr-lg-20">{ ReactHtmlParser(placeData.acf.subtitle) }</div> : "" }
                    </div>
                    <div className="col-12 col-lg-4 mb-lg-3 mt-3 lg-mt-0 d-flex justify-content-lg-end">
                        <div>
                          {placeData.acf.transportation === 'foot' ? footIcon : carIcon}
                          <p className="fs-s35 text-center">{placeData.acf.distance}</p>
                        </div>
                    </div>
                </div>
            </PageHero>

            <Section header="Nagłowek" hideHeader>
                <div className="row">
                    <div className="col-12 fs-s28 mt-4 mt-lg-8 mb-lg-8 d-flex justify-content-center">
                        <div className="pl-lg-10 pr-lg-10 text-center">{ ReactHtmlParser(placeContent[0]) }</div>
                    </div>
                    <div className="col-12 d-flex justify-content-center">
                        <SmoothScroll to="content" className={`btn btn-outline-primary fw-bold ${placeContent[1] ? '' : 'd-none'}`} smooth={true} offset={-55} duration={500} >
                            Więcej
                        </SmoothScroll>
                    </div>
                </div>
            </Section>

            {placeContent[1] ?
            <Section header="Treść" id="content" className="section-dark mt-4 mt-lg-6" hideHeader>
                <div className="row">
                    <div className="col-12 fs-s21 pt-3 pb-3 pt-lg-6 pb-lg-5 lh-lg">
                        <div className="columns">{ ReactHtmlParser(placeContent[1]) }</div>
                    </div>
                </div>
            </Section>
            : ""}

            {placeData.acf.gallery ?
                <Section header={placeData.acf.gallery_title}>
                    <SimpleReactLightbox>
                        <Gallery photos={placeData.acf.gallery}/>
                    </SimpleReactLightbox>
                </Section>
                :
                ""
            }

            <Section header="Poznaj nasze domy" className="houses">
                <div className="row d-flex justify-content-center align-content-center">
                    <div className="col-12 col-lg-8 text-center d-flex align-items-center flex-column d-none d-lg-block">
                        <div className="d-flex flex-row justify-content-center">
                            {houses.map((houseItem, i) => {
                                return (
                                    <Link key={i} className="text-decoration-none d-flex flex-row justify-content-center" to={'/budynki/' + houseItem.node.slug}>
                                        <House className="m-2" key={i} houseData={houseItem} />
                                    </Link>
                                )
                            })}
                        </div>
                    </div>
                    <div className="col col-lg-8 text-center d-flex align-items-center flex-column d-block d-lg-none">
                        <div className="w-75 d-flex flex-column align-content-center justify-content-center mb-6">
                            <Slider {...settings}>
                                {houses.map((houseItem, i) => {
                                    return (
                                        <Link key={i} className="text-decoration-none d-flex justify-content-center" to={'/budynki/' + houseItem.node.slug}>
                                            <House className="m-2" key={i} houseData={houseItem} />
                                        </Link>
                                    )
                                })}
                            </Slider>
                        </div>
                    </div>
                    <div className="d-flex flex-column text-center align-items-center mt-4 mb-6">
                        <p className="fs-s24 w-75 text-center mb-4 mb-lg-10">Mamy w ofercie większą chatę, a w planach kolejne otwarcia nowych mniejszych domków. Aby dowiedzieć się więcej i być na czasie kliknij poniżej</p>
                        <Link to="/znizki-ciekawostki-terminy/" className="btn btn-outline-primary fw-normal">Chcę więcej info</Link>
                    </div>
                </div>
            </Section>
        </Layout>
    )
}

postTemplate.propTypes = {
    data: PropTypes.object.isRequired,
}

export default postTemplate;

export const pageQuery = graphql`
    query($wordpressId: Int!) {
        placeData: wordpressWpPlaces(wordpress_id: {eq: $wordpressId}) {
            title
            slug
            link
            id
            content
            featured_media {
              localFile {
                publicURL
              }
            }
            acf {
              icon {
                localFile {
                  publicURL
                }
              }
              transportation
              distance
              gallery_title
              subtitle
              gallery {
                alt_text
                localFile {
                  childImageSharp {
                    fluid(quality: 100, maxWidth: 1280) {
                      aspectRatio
                      ...GatsbyImageSharpFluid_withWebp
                    }                                    
                    fixed(width: 200) {
                        ...GatsbyImageSharpFixed_withWebp
                    }
                    original {
                      src
                    }
                  }
                }
              }
            }
        }
        houses: allWordpressWpHouses(sort: {fields: wordpress_id, order: ASC})  {
            edges {
                node {
                    acf {
                        people
                        space
                        icon {
                            localFile {
                                publicURL
                            }
                        }
                    }
                    slug
                    title
                    wordpress_id
                }
            }
        }
    }
`;